"use client";

import ContactFormModal from "../ContactFormModal";
import Image from "next/image";
import ContentContainer from "../ContentContainer";
import { Button } from "@nextui-org/react";
import BackgroundImage from "./background.png";
import Rocket from "./rocket-growth-1.png";
import useDisclosure from "@/hooks/shared/useDisclosure";

const Banner1 = () => {
  const { open, isOpen, close } = useDisclosure();
  return (
    <div className="relative w-full overflow-hidden min-h-[180px] mt-3">
    <Image
      placeholder="blur"
      src={BackgroundImage}
      alt="Background"
      className="-z-10 object-fit-cover w-full h-full position-absolute"
    />

    <div className="relative z-10 flex items-center justify-center h-full full-w align-items-center flex-row my-10">
      <ContentContainer className="w-full" noPx>
        <div className="flex flex-row flex-wrap justify-between w-full">
          <div className="col-12 col-md-8 px-base px-5 md:px-0">
            <div className="mb-3">
              <Image src={Rocket} alt="rocket"/>
            </div>
            <h3 className="font-bold text-3xl text-adahubSecondary">
              Gọi tự động hàng loạt
            </h3>
            <div className="my-2 mt-3 max-w-[544px]">
              Trợ lý ảo gọi tự động tiếp cận 2000 khách hàng một giờ, tìm kiếm
              khách hàng tiềm năng và chăm sóc khách hàng tự động
            </div>
          </div>
          <div className="col-12 col-md-4 items-center justify-center flex flex-row">
            <Button
              // as={Link}
              variant="solid"
              onClick={() => {
                open();
              }}
              // href="#contact"
              className="bg-adahubSecondary mt-4 md:mt-0 rounded-3xl"
              color="primary">
              Đăng ký tư vấn miễn phí
            </Button>
          </div>
        </div>
      </ContentContainer>
    </div>
    <ContactFormModal isOpen={isOpen} open={open} close={close} />
  </div>
  );
};

export default Banner1;
